div.logo {
  float:left;
  width: 150px;
  height: 150px;
  display: inline;
}

.header{
  background: white;
  margin: 0px;
  overflow: hidden;  
}
