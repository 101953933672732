.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.dot-danger{
    background-color: red;
}

.dot-success{
    background-color: green;
}

.dot-primary{
    background-color: blue;
}

.dot-warning{
    background-color: yellow;
}