nav.mainNavBar{    
    background-color: #12139c;
    border: solid; /* Border for whole bar */
    border-color: #12139c;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    border-width: 1px; 
    border-radius: 3px;   
    font-weight: 600;
}

.custom-link-color{
    color: white !important;
}

.custom-link-color.active{
    color: white !important;
}

.inline-nav{
    display:flex;
}

.messageStyler{
    color: white;
    margin-right: 8px;
}

@media(max-width: 990px){
    .inline-nav .nav-link{
        padding-left: 10px !important;
    }
    .navbar-toggler{
        background-color: white !important;
    }
}

@media(max-width: 796px){
    .inline-nav .nav-link{
        padding-left: 5px !important;
    }
}

