body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav.mainNavBar{    
    background-color: #12139c;
    border: solid; /* Border for whole bar */
    border-color: #12139c;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    border-width: 1px; 
    border-radius: 3px;   
    font-weight: 600;
}

.custom-link-color{
    color: white !important;
}

.custom-link-color.active{
    color: white !important;
}

.inline-nav{
    display:-webkit-flex;
    display:flex;
}

.messageStyler{
    color: white;
    margin-right: 8px;
}

@media(max-width: 990px){
    .inline-nav .nav-link{
        padding-left: 10px !important;
    }
    .navbar-toggler{
        background-color: white !important;
    }
}

@media(max-width: 796px){
    .inline-nav .nav-link{
        padding-left: 5px !important;
    }
}


div.logo {
  float:left;
  width: 150px;
  height: 150px;
  display: inline;
}

.header{
  background: white;
  margin: 0px;
  overflow: hidden;  
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.dot-danger{
    background-color: red;
}

.dot-success{
    background-color: green;
}

.dot-primary{
    background-color: blue;
}

.dot-warning{
    background-color: yellow;
}
button.active{
    background-color: lightblue;
}

